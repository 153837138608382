import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { BlogMeta } from "../../components/blogMeta"
import Highlight from "react-highlight.js"

function renderHighlight(content: string, prompt: string = "$") {
  return <Highlight language="shellsession">{`${prompt} ${content}`}</Highlight>
}

export default function BlogPostVPSSetUpGuide() {
  return (
    <Layout>
      <SEO title="Full VPS Server Set Up Guide" />
      <main>
        <h1>Full VPS Server Set Up Guide Using Ubuntu 18.04 LTS</h1>
        <BlogMeta
          author="Primespot Engineering"
          published="May 21, 2020"
          tags={["Engineering", "DevOps"]}
        />
        <p>
          Here is an exact guide followed at Primespot to set up a fully
          functioning and secure VPS on cloud hosts such as DigitalOcean.
        </p>
        <p>
          This guide covers setting up basic server function, SSH, Nginx,
          Docker, a firewall, Fail2ban, security, etc.
        </p>
        <p>
          After following every step of this guide, you should have a fully
          functioning server that you can securely SSH into and host websites or
          applications.
        </p>
        <h2>Getting started</h2>
        <p>
          First, provision your server. This is usually done from the website of
          whatever cloud hosting provider you choose. I prefer DigitalOcean. But
          this guide should work with any of them.
        </p>
        <p>After provisioning your server, login to your server by SSH.</p>
        {renderHighlight("ssh root@your_server_ip")}
        <p>
          Now you need to add a new user. It would be a security vulnerability
          to rely on root for access to your server.
        </p>
        {renderHighlight("adduser michael", "#")}
        <p>
          Replace <em>michael</em> with whatever user name you would like.
        </p>
        <p>
          Now the user needs to be granted super user privileges so it has full
          access to the system.
        </p>
        {renderHighlight("usermod -aG sudo michael", "#")}
        <p>This grants your new user the same privileges as root.</p>
        <h2>SSH access to the server</h2>
        <p>
          SSH allows you to login to your server from the command line. The rest
          of this guide will use SSH for all of the commands.
        </p>
        <p>
          So far, we have been logging in the insecure way. We need to make SSH
          secure before we proceed
        </p>
        <p>
          Over the life over your server, you will have many "hacking" attempts
          on your server. These attempts will really just be scripts testing SSH
          for vulnerabilities. The following steps will secure your server from
          this type of threat.
        </p>
        <p>First, we need to add public key authentication.</p>
        <p>From your local machine (not the VPS server)...</p>
        {renderHighlight("ssh-keygen")}
        <p>
          When prompted for the key path, pick a path to a new key file, such as
          id_rsa_primespot_server
        </p>
        <p>
          <strong>Note:</strong> Don't use the key file name in the example
          above (id_rsa_primespot_server). Replace this name with a key name of
          your choosing. Prepending the name with <em>id_rsa</em> as I did in
          the example is a fine practice thought. This way, you know exactly
          what the file is by looking at the name.
        </p>
        <p>Next you have to copy the public key to the server.</p>
        <p>
          I am going to show you two ways to do this. The first option is the
          manual option. The second will be easier and automated.
        </p>
        <p>
          I generally prefer the second option, but it's good to show how to
          manually do it as well.
        </p>
        <div className="bg-blue-50 p-2 my-4">
          <h3 className="text-blue-900 mb-8 text-2xl">Method 1</h3>
          <p>To get the public key on your local machine:</p>
          {renderHighlight("cat ~/.ssh/id_rsa_stcc1.pub")}
          <p>
            Then copy the key to the clipboard (Ctrl-C or Cmd-C typically). Then
            ssh back into the server as the root user.
          </p>
          {renderHighlight("ssh root@server_ip_address")}
          <p>
            Switch users to the user you created earlier. I am using the user{" "}
            <em>michae</em> for this example.
          </p>
          {renderHighlight("su - michael", "#")}
          <p>
            Now we'll configure the <em>ssh</em> directory in your home
            directory.
          </p>
          {renderHighlight("cd")}
          {renderHighlight("mkdir ./ssh")}
          {renderHighlight("chmod 700 ./ssh")}
          <p>
            Next, the public key needs to be pasted into the authorized_keys
            file inside the ~/.ssh directory.
          </p>
          <p>
            <strong>Note:</strong> We are using <em>vim</em> for this file edit.
            Vim is perfect for this use case since we only have terminal access.
            Vim is also a very popular editor that many software development
            shops use including Primespot. Check our blog for posts about how to
            use Vim effectively.
          </p>
          {renderHighlight("vim ~/.ssh/authorized_keys")}
          <p>Paste the public key in.</p>
          <p>
            <strong>Note:</strong> To paste in vim, press the <em>i</em> key to
            enter into what's called <em>insert mode</em>. Then press Ctrl-v on
            Windows/Linux or Cmd-v on Mac. If this doesn't work, then you are
            probably not using a terminal that supports copy and paste
            functions. Google for a terminal program that will work for your
            needs. On Mac, I recommend <em>Hyper</em>
          </p>
          <p>After pasting the public key in, save and quit out of vim.</p>
          <p>
            <strong>Note:</strong> To save and quit out of vim, first press the{" "}
            <em>escape</em> key to exit <em>insert mode</em> and enter what is
            called <em>normal mode</em>. Afterwards, type <em>:wq</em> to save
            and exit. That is <em>colon</em>, <em>w</em>, <em>q</em>.
          </p>
        </div>
        <div className="bg-blue-50 my-4 p-2">
          <h3 className="text-2xl text-blue-900 mb-8">Method 2</h3>
          <p>
            Alternatively, you can use the <em>ssh-copy-id</em> utility on the
            local machine. Just specify the correct key file in the command.
          </p>
          <p>On your local machine, type:</p>
          {renderHighlight("ssh-copy-id -i ~/.ssh/mykey user@host")}
          <p>
            Replace <em>~/.ssh/mykey</em> with the path and file name of your
            key file.
          </p>
          <p>
            Also replace <em>user</em> and <em>host</em> with the user name and
            host address of your VPS.
          </p>
        </div>
        <p>
          If using a custom key file instead of the default (we are in our
          example), you must add the private key on the local machine using
          ssh-add. This adds the custom key to the registry, but it only adds it
          in memory. This means that you’ll have to repeat this process every
          time you restart your local machine.
        </p>
        {renderHighlight("ssh-add ~/.ssh/my_private_key")}
        <p>
          Replace <em>~/.ssh/my_private_key</em> with the path and name of your
          private key file on your local machine.
        </p>
        <p>
          <strong>Note:</strong> The private key is the key file that doesn’t
          end in .pub.
        </p>
        <p>
          Alternatively to using ssh-add, you can specify the identity file at
          the command line in the ssh command like so:
        </p>
        {renderHighlight("ssh -i /path/to/identity user@host")}
        <p>
          Another option still is to create an ssh config file on your local
          machine at ~/.ssh/config. This will specify options for different
          connections.
        </p>
        <Highlight language="shellsession">
          Host myservername
          <br />
          &nbsp;&nbsp;User michael
          <br />
          &nbsp;&nbsp;HostName myserver.com
          <br />
          &nbsp;&nbsp;IdentityFile ~/.ssh/myserverprivatekey
          <br />
        </Highlight>
        <div className="my-4 border-yellow-400 border-l-4 bg-yellow-50 pl-4 py-2 pr-1">
          <h4 className="mb-4 text-2xl text-yellow-900">Tip</h4>
          <p>
            The format used in the example above can be used multiple times in
            the config file if you are using multiple VPS servers.
          </p>
        </div>
        <p>With this setup, you can simply type:</p>
        {renderHighlight("ssh myservername")}
        <p>
          This will automatically connect you using the correct host, user, and
          key.
        </p>
        <p>Now restrict the permissions to this file on the server machine.</p>
        {renderHighlight("chmod 600 ~/.ssh/authorized_keys")}
        <p>
          Now attempt to login to the server using the public key instead of a
          password. If it works, proceed to the following instructions.
        </p>
        <p>
          In the next steps, we are going to disable password authentication.
          This will significantly improve security by only allowing those with a
          valid SSH key to login.
        </p>
        <p>
          We'll start by entering vim again and editing the SSH config file. In
          this file, we will set/verify a few options.
        </p>
        {renderHighlight("sudo vim /etc/ssh/sshd_config")}
        <p>
          Find the line that specifies PasswordAuthentication, uncomment it, and
          change the value to <em>no</em>. It should look like this:
        </p>
        {renderHighlight("PasswordAuthentication no", "")}
        <p>Ensure the following keys are set like this:</p>
        <Highlight language="shellsession">
          PubkeyAuthentication yes
          <br />
          ChallengeResponseAuthentication no
          <br />
          PermitRootLogin no
          <br />
        </Highlight>
        <p>
          Save the file and exit by typing <em>&lt;ESC&gt; :wq</em>.
        </p>
        <p>
          Next we need to restart the SSH server. It is common practice to
          restart a service whenever config files are changed. If you edit a
          config file on a Unix-based operating system and forget to restart the
          service, it is likely that the changes won't take effect.
        </p>
        {renderHighlight("sudo systemctl reload sshd")}
        <p>
          Before logging out, open a new terminal window and test logging in by
          typing:
        </p>
        {renderHighlight("ssh michael@your_server_ip")}
        <p>
          This step is critical. If the config isn't set up correctly and you
          can't login passwordless, you won't be able to login at all. Fixing
          the problem will require you to log into the server directly through
          your VPS provider and enable password authentication.
        </p>
        <p>
          If you have a domain name pointed to the server and you want to be
          able to ssh into the server by typing the domain name instead of the
          IP address, you need to add this line to your /etc/hosts file on your
          local machine:
        </p>
        {renderHighlight("server_ip_address domain_name", "")}
        <p>
          Replace server_ip_address with the IP address of the server. Replace
          domain_name with the domain name pointing to the server. These fields
          are separated by whitespace. A single space will do. Tabs works as
          well.
        </p>
        <p>
          You can edit your /etc/hosts file by typing the following command at
          the command line:
        </p>
        {renderHighlight("sudo vim /etc/hosts")}
        <p>
          This concludes our section on setting up SSH. Now that we have
          completed these steps, we have secure access to our server. This will
          be essential for completing the rest of the steps in this guide as
          well as maintaining your server from now on.
        </p>
        <h2>Firewall</h2>
        <p>
          Now that we are finished with setting up SSH, the hardest part is
          over.
        </p>
        <p>
          In fact, it's pretty much smooth sailing from here until we set up
          Nginx.
        </p>
        <p>
          Now it's time to set up the firewall. We will start by setting a few
          firewall settings. Then we will enable the firewall.
        </p>
        <p>Allow OpenSSH through the firewall:</p>
        {renderHighlight("sudo ufw allow OpenSSH")}
        {renderHighlight("sudo ufw allow 80/tcp")}
        {renderHighlight("sudo ufw allow 443/tcp")}
        <p>Now activate the firewall.</p>
        {renderHighlight("sudo ufw enable")}
        <p>Now we're going to rate limit ssh connections.</p>
        {renderHighlight("sudo ufw limit ssh/tcp")}
        <p>Check this status by typing:</p>
        {renderHighlight("sudo ufw status")}
        <p>And that's it. We now have a working firewall.</p>
        <h2>Fail2ban</h2>
        <p>
          Fail2ban is a tool to help prevent unauthorized SSH attempts. It's an
          excellent security addition and takes only a few minutes to set up.
        </p>
        {renderHighlight("sudo apt-get update")}
        {renderHighlight("sudo apt-get install fail2ban sendmail")}
        <p>The sendmail package is required to send email notifications.</p>
        <p>
          Fail2ban uses the file /etc/fail2ban/jail.conf to maintain its
          settings. These settings can be overridden by creating and editing a
          jail.local file.
        </p>
        <p>
          So first you need to make a copy of the jail.conf file named
          jail.local. Then edit the settings to fit the server’s needs.
          Alternatively, you can simply create a jail.local file and add your
          config.
        </p>
        {renderHighlight(
          "sudo cp /etc/fail2ban/jail.conf /etc/fail2ban/jail.local"
        )}
        <p>Below is a good starting point for the jail.local file.</p>
        <Highlight language="">
          DEFAULT]
          <br />
          <br />
          # email address to receive notifications.
          <br />
          destemail = root@localhost <br />
          # the email address from which to send emails.
          <br />
          sender = root@&lt;fq-hostname>
          <br />
          # name on the notification emails.
          <br />
          sendername = Fail2Ban <br />
          # email transfer agent to use.
          <br />
          mta = sendmail <br />
          <br />
          # see action.d/ufw.conf
          <br />
          actionban = ufw.conf
          <br />
          # see action.d/ufw.conf
          <br />
          actionunban = ufw.conf <br />
          <br />
          [sshd]
          <br />
          enabled = true
          <br />
          port = ssh
          <br />
          filter = sshd
          <br />
          # the length of time between login attempts for maxretry.
          <br />
          findtime = 600
          <br />
          # attempts from a single ip before a ban is imposed.
          <br />
          maxretry = 5<br />
          # the number of seconds that a host is banned for.
          <br />
          bantime = 3600
          <br />
        </Highlight>
        <p>
          Next you need to set up Fail2ban to start when the system starts up
          and to begin running the service.
        </p>
        {renderHighlight("sudo systemctl enable fail2ban")}
        {renderHighlight("sudo systemctl start fail2ban")}
        <p>And that's all it takes to set up Fail2ban.</p>
        <h2>Swap file</h2>
        <p>
          Swap files are files on the hard disk that the operating system can
          use when memory is low. The operating system will actually write
          in-memory data to the swap file instead of storing it in memory.
        </p>
        <p>
          While this will enable you to have a bit of extra memory when memory
          is low, it's also much slower. Still, it's a good idea to configure it
          in case you need it.
        </p>
        <p>
          First, check if swap is already enabled (it shouldn’t be on
          digitalocean).
        </p>
        {renderHighlight("sudo swapon --show")}
        <p>If the result is empty, there is no swap enabled.</p>
        <p>First, allocate the file necessary for the swap file.</p>
        {renderHighlight("sudo fallocate -l 1G /swapfile")}
        <p>If this fails, use this command instead:</p>
        {renderHighlight(
          "sudo dd if=/dev/zero of=/swapfile bs=1024 count=1048576"
        )}
        <p>Then set the permissions.</p>
        {renderHighlight("sudo chmod 600 /swapfile")}
        <p>Use the mkswap utility to create a swap area on the file.</p>
        {renderHighlight("sudo mkswap /swapfile")}
        <p>Activate the swap file:</p>
        {renderHighlight("sudo swapon /swapfile")}
        <p>Edit the /etc/fstab file.</p>
        {renderHighlight("sudo vim /etc/fstab")}
        <p>Paste the following line in:</p>
        {renderHighlight("/swapfile swap swap defaults 0 0")}
        <p>Verify the swap file is active with this command:</p>
        {renderHighlight("sudo swapon --show")}
        <p>
          The swappiness value of the system determines how likely the system is
          to use the swap file. The lower the number, the less likely the swap
          space will be used.
        </p>
        <p>
          Use the following command to check the swappiness value of the system:
        </p>
        {renderHighlight("cat /proc/sys/vm/swappiness")}
        <p>
          The default is 60. 10 is probably a better number for a production
          server. Set the swappiness value with the command:
        </p>
        {renderHighlight("sudo sysctl vm.swappiness=10")}
        <p>
          To persist this setting across reboots, open the file /etc/sysctl.conf
        </p>
        {renderHighlight("sudo vim /etc/sysctl.conf")}
        <p>Then append the following line in there:</p>
        {renderHighlight("vm.swappiness=10")}
        <p>
          Exit vim by typing <em>:wq</em>
        </p>
        <p>
          This concludes the section of this guide involving basic setup of the
          server. Next, we’ll install and set up Docker.
        </p>
        <h2>Docker</h2>
        <p>
          Docker is a system for containerization. Put simply, it runs a
          full-blown system in a single process making it easy to maintain,
          move, and deploy the system.
        </p>
        <p>
          Each system contains a full Linux distribution, essential tools, and
          whatever is needed to help your app run.
        </p>
        <p>
          This can be a PostgreSQL database, a Node process, a Rails app...It
          can be anything really.
        </p>
        <p>
          It's also common to run multiple of these containers for a single
          application.
        </p>
        <p>
          More information about Docker is outside of the scope of this article.
          Pay attention to our blog for more information, however.
        </p>
        <p>
          Now that the introduction is out of the way, it's time to get the
          Docker service running on our VPS. This will help us with production
          deployments that are using Docker. If you aren't planning to use
          Docker, you can skip this step.
        </p>
        <p>
          It’s always a good idea to update the packages before doing anything
          with <em>apt</em>.
        </p>
        {renderHighlight("sudo apt update")}
        <p>Next there are prerequisite packages that need to be installed.</p>
        {renderHighlight(
          "sudo apt install apt-transport-https ca-certificates curl software-properties-common"
        )}
        <p>
          You need to add the GPG key from the official Docker repository to the
          system.
        </p>
        {renderHighlight(
          "curl -fsSL https://download.docker.com/linux/ubuntu/gpg | sudo apt-key add -"
        )}
        <p>Now the Docker repository needs to be added to APT sources.</p>
        {renderHighlight(
          `sudo add-apt-repository "deb [arch=amd64] https://download.docker.com/linux/ubuntu bionic stable"`
        )}
        <p>Update packages again.</p>
        {renderHighlight("sudo apt update")}
        <p>
          The following command ensures that you are installing from the Docker
          repo instead of the official Ubuntu repo.
        </p>
        {renderHighlight("sudo apt-cache policy docker-ce")}
        <p>Whew. That was a lot. Now we can finally install Docker.</p>
        {renderHighlight("sudo apt install docker-ce")}
        <p>Confirm that Docker is running.</p>
        {renderHighlight("sudo systemctl status docker")}
        <p>
          Now your user needs to be added to the docker group to avoid having to
          use sudo with every docker command.
        </p>
        {renderHighlight("sudo usermod -aG docker ${USER}")}
        <p>Reload your terminal:</p>
        {renderHighlight("su - ${USER}")}
        <p>Confirm that you’re in the docker group:</p>
        {renderHighlight("id -nG")}
        <h2>Nginx</h2>
        <p>
          With Docker installed, we can actually run Nginx in a Docker
          container. While the approach is pretty cool, I generally prefer to
          install and run Nginx locally on the system. Therefore, this guide
          will cover installing Nginx locally on the system.
        </p>
        <p>
          <strong>Note:</strong> This guide is not going to cover setting up and
          configuring Nginx. That is a huge topic on its own.
        </p>
        <p>To start, we will install Nginx.</p>
        {renderHighlight("sudo apt update")}
        {renderHighlight("sudo apt install nginx")}
        <p>
          Next, we need to ensure that the firewall we set up earlier will allow
          Nginx to pass through.
        </p>
        <p>
          To see what applications are available to add to UFW, use the command:
        </p>
        {renderHighlight("sudo ufw app list")}
        <p>'Nginx Full' should be on the list.</p>
        <p>You can add 'Nginx Full' to UFW with the command:</p>
        {renderHighlight("sudo ufw allow 'Nginx Full'")}
        <p>Nginx Full enables both http and https access.</p>
        <p>Verify the changes with the command:</p>
        {renderHighlight("sudo ufw status")}
        <p>Check the status of the Nginx server with:</p>
        {renderHighlight("systemctl status nginx")}
        <p>
          Verify the web server is working in a web browser by first getting
          your IP address:
        </p>
        {renderHighlight("curl -4 icanhazip.com")}
        <p>
          Then loading <em>http://your_server_ip_address</em> in a web browser.
          You should see the Nginx welcome page. If this works, Nginx is good to
          go. If you have a website ready, create an Nginx server block and
          upload it.
        </p>
        <h2>HTTPS support</h2>
        <p>
          The next step is to enable HTTPS. To do this, you must first have a
          domain name pointing to the server.
        </p>
        <p>
          First, add the Certbot repository to apt. This will ensure you get the
          newest version installed.
        </p>
        {renderHighlight("sudo add-apt-repository ppa:certbot/certbot")}
        <p>Next install Certbot’s Nginx package:</p>
        {renderHighlight("sudo apt install python-certbot-nginx")}
        <p>
          Next, ensure Nginx config files and server block files are set up
          correctly. The config file for the domain you are trying to get an SSL
          certificate for should have the correct server_name directive.
        </p>
        <p>
          Also, make sure UFW is configured to allow HTTPS through the firewall:
        </p>
        {renderHighlight("sudo ufw status")}
        <p>Run this command to get an SSL certificate for the chosen domain:</p>
        {renderHighlight("sudo certbot --nginx -d domain_name")}
        <p>Replace domain_name with your domain name.</p>
        <p>
          Certificates will automatically renew due to a cron job that Certbot
          set up. To verify the renewal process should proceed unhindered, run
          this dry run of the renewal:
        </p>
        {renderHighlight("sudo certbot renew --dry-run")}
        <p>
          If you find no errors, test the website domain with https://. If
          everything is correct, you are done.
        </p>
        <h2>Conclusion</h2>
        <p>
          I know that this guide is enormous. It will likely take you anywhere
          between two and four hours to work through it. It might be a bit
          quicker if you're fast and experienced when it comes to DevOps.
        </p>
        <p>
          Still, this guide follows many best practices when it comes to setting
          up a production server.
        </p>
        <p>
          Furthermore, with HTTPS and Docker enabled, you are completely ready
          to launch your application.
        </p>
        <p>Happy hacking!</p>
      </main>
    </Layout>
  )
}
